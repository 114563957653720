import React from 'react';
import '../style/StarRating.css';

function StarRating({ rating, max = 10 }) { // Set max to 10 for a 10-star scale
  const numericRating = parseFloat(rating);
  const safeRating = isNaN(numericRating) ? 0 : numericRating;

  const fullStars = Math.floor((safeRating / max) * 10); // Adjust calculation for full stars out of 10
  const hasHalfStar = (safeRating * 10) % 10 >= 5; // Determine if there should be a half star
  const emptyStars = 10 - fullStars - (hasHalfStar ? 1 : 0); // Adjust empty stars for a 10-star scale

  return (
    <div className="star-rating">
      {/* Full Stars */}
      {Array(fullStars)
        .fill(0)
        .map((_, index) => (
          <span key={`full-${index}`} className="star full-star">★</span>
        ))}

      {/* Half Star */}
      {hasHalfStar && <span className="star half-star">★</span>}

      {/* Empty Stars */}
      {Array(emptyStars)
        .fill(0)
        .map((_, index) => (
          <span key={`empty-${index}`} className="star empty-star">★</span>
        ))}

      {/* Display the numeric rating */}
      <span className="rating-number">({safeRating.toFixed(1)})</span>
    </div>
  );
}

export default StarRating;
