import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../style/MyReviews.css';

function MyReviews() {
  const [reviews, setReviews] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [reviewToDelete, setReviewToDelete] = useState(null);
  const location = useLocation();
  const userId = location.state?.userId;

  useEffect(() => {
    if (!userId) {
      console.error("User ID is undefined. Cannot fetch reviews.");
      return;
    }

    const fetchReviews = async () => {
      try {
        console.log(userId)
        const reviewsResponse = await fetch(`/api/reviews?userId=${userId}`);
        if (reviewsResponse.ok) {
          const reviewsData = await reviewsResponse.json();
          console.log(reviewsData)
          setReviews(reviewsData);
        } else {
          console.error('Failed to fetch reviews');
        }
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, [userId]);

  const handleDeleteReview = async () => {
    if (reviewToDelete) {
      try {
        const response = await fetch(`/api/delete-review/${reviewToDelete.reviewId}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          setReviews(reviews.filter((review) => review.reviewId !== reviewToDelete.reviewId));
          setShowPopup(false);
        } else {
          console.error('Failed to delete the review');
        }
      } catch (error) {
        console.error('Error deleting review:', error);
      }
    }
  };

  const openDeletePopup = (review) => {
    setReviewToDelete(review);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setReviewToDelete(null);
  };

  return (
    <div className="my-reviews-container">
      <h1>My Reviews</h1>
      {reviews.length > 0 ? (
        <ul className="reviews-list">
          {reviews.map((review, index) => (
            <li key={index} className="review-item">
              <h2>Review for: {review.class.CourseTitle}</h2>
              <p><strong>Professor Name:</strong> {review.professorName}</p>
              <p><strong>Year:</strong> {review.year}</p>
              <p><strong>Online Class:</strong> {review.onlineClass ? 'Yes' : 'No'}</p>
              <p><strong>Concept Difficulty:</strong> {review.conceptDifficulty}</p>
              <p><strong>Assignment Difficulty:</strong> {review.assignmentDifficulty}</p>
              <p><strong>Grade Difficulty:</strong> {review.gradeDifficulty}</p>
              <p><strong>Usefulness:</strong> {review.usefulness}</p>
              <p><strong>Interest:</strong> {review.interest}</p>
              <button className="delete-button" onClick={() => openDeletePopup(review)}>
                🗑️
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>You have not written any reviews yet.</p>
      )}

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <p>Are you sure you want to delete this review?</p>
            <button onClick={handleDeleteReview}>Yes</button>
            <button onClick={closePopup}>No</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyReviews;
