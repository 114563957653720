import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ReviewItem from './ReviewItem'; // Import the new ReviewItem component
import SuccessBanner from './SuccessBanner';
import '../style/ClassDetails.css';
import StarRating from './StarRating';

function ClassDetails({ onRateCourse }) {
  const { classId } = useParams();
  const [classInfo, setClassInfo] = useState(null);
  const [reviews, setReviews] = useState([]);
  const location = useLocation();
  const [showBanner, setShowBanner] = useState(false);
  const reviewSuccess = location.state?.reviewSuccess;
  const averages = location.state?.averages || null;

  useEffect(() => {
    fetch(`/api/classes/${classId}`)
      .then(response => response.json())
      .then(data => setClassInfo(data))
      .catch(error => console.error('Error fetching class info:', error));

    fetch(`/api/reviews?classId=${classId}`)
      .then(response => response.json())
      .then(data => setReviews(data))
      .catch(error => console.error('Error fetching reviews:', error));
  }, [classId]);

  useEffect(() => {
    if (reviewSuccess) {
      setShowBanner(true);
    }
  }, [reviewSuccess, reviews]);

  return (
    <div className="class-details-container">
      {showBanner && <SuccessBanner message="Your review has been successfully recorded!" onClose={() => setShowBanner(false)} />}
      {classInfo ? (
        <>
          <h1>{classInfo.CourseTitle}</h1>
          <p><strong>Class Number:</strong> {classInfo.classNum}</p>
          <p><strong>Department:</strong> {classInfo.department}</p>
          <p><strong>Description:</strong> {classInfo.courseDescription}</p>

          {averages ? (
            <div className="averages">
              <h2>Average Ratings</h2>
              <p><strong>Number of Reviews:</strong> {averages.count ?? 0}</p>
              <p><strong>Average Hours:</strong> {averages.avgHours ?? 'N/A'}</p>
              <div className="average-item">
                <span>Concept Difficulty:</span>
                <StarRating rating={averages.conceptDifficulty} max={10} />
              </div>
              <div className="average-item">
                <span>Assignment Difficulty:</span>
                <StarRating rating={averages.assignmentDifficulty} max={10} />
              </div>
              <div className="average-item">
                <span>Grade Difficulty:</span>
                <StarRating rating={averages.gradeDifficulty} max={10} />
              </div>
              <div className="average-item">
                <span>Overall Difficulty:</span>
                <StarRating rating={averages.difficulty} max={10} />
              </div>
              <div className="average-item">
                <span>Usefulness:</span>
                <StarRating rating={averages.usefulness} max={10} />
              </div>
              <div className="average-item">
                <span>Interest:</span>
                <StarRating rating={averages.interest} max={10} />
              </div>
            </div>
          ) : (
            <p>Loading average ratings...</p>
          )}

          <button onClick={() => onRateCourse(classId)} className="rate-course-button">
            Rate this Course
          </button>

          <h2>Reviews for this Class</h2>
          {reviews.length > 0 ? (
            <ul className="reviews-list">
              {reviews.map((review, index) => (
                <ReviewItem key={index} review={review} />
              ))}
            </ul>
          ) : (
            <p>No reviews available for this class.</p>
          )}
        </>
      ) : (
        <p>Loading class information...</p>
      )}
    </div>
  );
}

export default ClassDetails;
