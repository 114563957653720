import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StarRating from './StarRating';

function TopClasses() {
  const [topClasses, setTopClasses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch top 10 classes from the backend
    fetch(`/api/top_classes`)
      .then((response) => response.json())
      .then((data) => {
        setTopClasses(data);
      })
      .catch((error) => {
        console.error('Error fetching top classes:', error);
      });
  }, []);

  const handleViewClass = (classId, course) => {
    // Extract averages
    const averages = {
      avgHours: course.avgHours,
      conceptDifficulty: course.conceptDifficulty,
      assignmentDifficulty: course.assignmentDifficulty,
      gradeDifficulty: course.gradeDifficulty,
      difficulty: course.difficulty,
      usefulness: course.usefulness,
      interest: course.interest,
      numReviews: course.numReviews,
    };

    navigate(`/class/${classId}`, {
      state: {
        averages: averages, // Pass the averages to ClassDetails
      },
    });
  };

  return (
    <section className="popular-classes">
      <h2>Popular Classes</h2>
      <ul className="classes-list">
        {topClasses.map((course) => (
          <li key={course.classId} className="class-item">
            <h3>{course.CourseTitle}</h3>
            <p><strong>Department:</strong> {course.department}</p>
            <p><strong>Class Number:</strong> {course.classNum}</p>
            <p><strong>Number of Reviews:</strong> {course.numReviews}</p>
            <p><strong>Average Hours:</strong> {course.avgHours}</p>

            <div className="ratings">
            <div className="rating-item">
                <span>Overall Difficulty:</span>
                <StarRating rating={course.difficulty} max={10} />
              </div>
              <div className="rating-item">
                <span>Concept Difficulty:</span>
                <StarRating rating={course.conceptDifficulty} max={10} />
              </div>
              <div className="rating-item">
                <span>Assignment Difficulty:</span>
                <StarRating rating={course.assignmentDifficulty} max={10} />
              </div>
              <div className="rating-item">
                <span>Grade Difficulty:</span>
                <StarRating rating={course.gradeDifficulty} max={10} />
              </div>
              <div className="rating-item">
                <span>Usefulness:</span>
                <StarRating rating={course.usefulness} max={10} />
              </div>
              <div className="rating-item">
                <span>Interest:</span>
                <StarRating rating={course.interest} max={10} />
              </div>
            </div>

            <button onClick={() => handleViewClass(course.classId, course)}>
              View Class
            </button>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default TopClasses;

