import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ugabanner from '../assets/images/ugabanner.png';
import '../style/Homepage.css';
import Footer from './Footer';
import TopClasses from './TopClasses'; // Import the new component

function Homepage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const [department, setDepartment] = useState('');
  const [courseNumber, setCourseNumber] = useState('');

  useEffect(() => {
    // Check if we need to show the popup
    if (location.state?.showPopup) {
      setShowPopup(true);
      // Remove the state after showing the popup to prevent it from showing again
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  const handleSearch = () => {
    if (department.length !== 4) {
      alert('Department code must be exactly 4 letters.');
      return;
    }
    navigate('/ViewClasses', { state: { department, courseNumber } });
  };

  const handleAdvancedSearch = () => {
    navigate('/ViewClasses');
  };

  return (
    <div className="app">
      <main className="main-content">
        <section className="intro">
          <h2>UGA Course Genie</h2>
          <p>See reviews, get advice, and find helpful resources for university courses</p>
          <div className="search">
            <input 
              id="dept-search" 
              type="text" 
              placeholder="Department (e.g. MATH)" 
              value={department} 
              onChange={(e) => setDepartment(e.target.value.toUpperCase())} 
            />
            <input 
              id="course-search" 
              type="text" 
              placeholder="Course Number (e.g. 3010)" 
              value={courseNumber} 
              onChange={(e) => setCourseNumber(e.target.value)} 
            />
            <button onClick={handleSearch}>Search</button>
          </div>
          <button onClick={handleAdvancedSearch}>Advanced Search</button>
        </section>
        
        {/* Display top 10 popular classes */}
        <TopClasses />

      </main>

    

      <Footer />

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Thank you!</h2>
            <p>Your message has been sent. Our staff will get back to you within 24 hours.</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Homepage;
