import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/Profile.css';

function Profile({ onLogout }) {
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const response = await fetch('/api/profile', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserInfo(data);
      } else {
        console.error('Failed to fetch user info');
      }
    };

    fetchUserInfo();
  }, []);

  const handleLogout = async () => {
    const response = await fetch('/api/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      sessionStorage.removeItem('loggedIn');
      onLogout();
      navigate('/');
    } else {
      console.error('Failed to log out');
    }
  };

  const handleBackToHome = () => {
    navigate('/');
  };

  const handleMyReviews = () => {
    // Navigate to MyReviews and pass userId in the state
    console.log(userInfo)
    navigate('/my-reviews', { state: { userId: userInfo.userId } });
  };

  return (
    <div className="profile-container">
      <h1>Your Profile</h1>
      <p><strong>Username:</strong> {userInfo.username}</p>
      <p><strong>Email:</strong> {userInfo.email}</p>
      <p><strong>Major:</strong> {userInfo.major}</p>
      <p><strong>Grade:</strong> {userInfo.grade}</p>
      <div className="profile-buttons">
        <button onClick={handleBackToHome} className="profile-button">Back to Homepage</button>
        <button onClick={handleMyReviews} className="profile-button my-reviews-button">My Reviews</button>
        <button onClick={handleLogout} className="profile-button logout-button">Log Out</button>
      </div>
    </div>
  );
}

export default Profile;
