import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../style/ViewClasses.css';
import StarRating from './StarRating';
import AdvancedSearch from './AdvancedSearch';

function ViewClasses() {
  const [classes, setClasses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsData, setReviewsData] = useState({});
  const navigate = useNavigate();
  const classesPerPage = 20;

  // Retrieve initial search parameters from homepage navigation
  const location = useLocation();
  const { department: initialDepartment, courseNumber: initialCourseNumber } = location.state || {};

  // State for search parameters, initialized with homepage values
  const [searchParams, setSearchParams] = useState({
    department: initialDepartment || '',
    courseNumber: initialCourseNumber || '',
    minReviews: 0,
    minDifficulty: 0,
    maxDifficulty: 10,
    minUsefulness: 0,
    minInterest: 0,
    minAvgHours: 0,
    maxAvgHours: null,
    minConceptDifficulty: 0,
    maxConceptDifficulty: 10,
    minAssignmentDifficulty: 0,
    maxAssignmentDifficulty: 10,
    minGradeDifficulty: 0,
    maxGradeDifficulty: 10,
    sortBy: '',
    classLevels: {
      level1000: false,
      level2000: false,
      level3000: false,
      level4000: false,
      level5000: false,
    },
  });

  useEffect(() => {
    let query = '';

    // Use searchParams values for the query
    const { department, courseNumber, minReviews, minDifficulty, maxDifficulty, minUsefulness, minInterest, minAvgHours, maxAvgHours, minConceptDifficulty, maxConceptDifficulty, minAssignmentDifficulty, maxAssignmentDifficulty, minGradeDifficulty, maxGradeDifficulty, sortBy, classLevels } = searchParams;

    if (department) {
      const departmentsArray = department.split(',').map(dept => dept.trim());
      if (departmentsArray.length > 0) {
        query += `?department=${departmentsArray.join(',')}`;
      }
    }
    if (courseNumber) {
      query += `${query ? '&' : '?'}courseNumber=${courseNumber}`;
    }
    if (minReviews) {
      query += `${query ? '&' : '?'}minReviews=${minReviews}`;
    }
    if (minDifficulty || maxDifficulty) {
      query += `${query ? '&' : '?'}minDifficulty=${minDifficulty}&maxDifficulty=${maxDifficulty}`;
    }
    if (minUsefulness) {
      query += `${query ? '&' : '?'}minUsefulness=${minUsefulness}`;
    }
    if (minInterest) {
      query += `${query ? '&' : '?'}minInterest=${minInterest}`;
    }
    if (minAvgHours) {
      query += `${query ? '&' : '?'}minAvgHours=${minAvgHours}`;
    }
    if (maxAvgHours !== null) {
      query += `${query ? '&' : '?'}maxAvgHours=${maxAvgHours}`;
    }
    if (minConceptDifficulty || maxConceptDifficulty) {
      query += `${query ? '&' : '?'}minConceptDifficulty=${minConceptDifficulty}&maxConceptDifficulty=${maxConceptDifficulty}`;
    }
    if (minAssignmentDifficulty || maxAssignmentDifficulty) {
      query += `${query ? '&' : '?'}minAssignmentDifficulty=${minAssignmentDifficulty}&maxAssignmentDifficulty=${maxAssignmentDifficulty}`;
    }
    if (minGradeDifficulty || maxGradeDifficulty) {
      query += `${query ? '&' : '?'}minGradeDifficulty=${minGradeDifficulty}&maxGradeDifficulty=${maxGradeDifficulty}`;
    }
    if (sortBy) {
      query += `${query ? '&' : '?'}sortBy=${sortBy}`;
    }
    if (Object.values(classLevels).some(value => value)) {
      query += `${query ? '&' : '?'}classLevels=`;
      if (classLevels.level1000) query += '1000,';
      if (classLevels.level2000) query += '2000,';
      if (classLevels.level3000) query += '3000,';
      if (classLevels.level4000) query += '4000,';
      if (classLevels.level5000) query += '5000,';
      query = query.slice(0, -1);
    }

    // Fetch classes from the backend using the query string
    fetch(`/api/classes${query}`)
      .then(response => response.json())
      .then(data => {
        setClasses(data);

        const reviewsData = {};
        data.forEach(course => {
          reviewsData[course.classId] = {
            avgHours: course.avgHours,
            conceptDifficulty: course.conceptDifficulty,
            assignmentDifficulty: course.assignmentDifficulty,
            gradeDifficulty: course.gradeDifficulty,
            difficulty: course.difficulty,
            usefulness: course.usefulness,
            interest: course.interest,
            count: course.numReviews,
          };
        });

        setReviewsData(reviewsData);
      })
      .catch(error => console.error('Error fetching classes:', error));
  }, [searchParams]);

  const handleViewClass = (classId, averages) => {
    navigate(`/class/${classId}`, {
      state: { averages }
    });
  };

  const handleContactUs = () => {
    navigate('/contact-us');
  };

  const handleAdvancedSearch = (filters) => {
    setSearchParams({
      ...filters,
    });
  };

  const indexOfLastClass = currentPage * classesPerPage;
  const indexOfFirstClass = indexOfLastClass - classesPerPage;
  const currentClasses = classes.slice(indexOfFirstClass, indexOfLastClass);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="view-classes-container">
      <h1>Class Search Results</h1>

      <AdvancedSearch
        initialDepartment={searchParams.department}
        initialCourseNumber={searchParams.courseNumber}
        onSearch={handleAdvancedSearch}
      />

      {currentClasses.length > 0 ? (
        <ul className="classes-list">
          {currentClasses.map((course) => (
            <li key={course.classId} className="class-item">
              <h2>{course.CourseTitle}</h2>
              <p><strong>Department:</strong> {course.department}</p>
              <p><strong>Class Number:</strong> {course.classNum}</p>

              {reviewsData[course.classId] ? (
                <div className="ratings">
                  <p><strong>Number of Reviews:</strong> {reviewsData[course.classId].count ?? 0}</p>
                  <p><strong>Average Hours:</strong> {reviewsData[course.classId].avgHours ?? 0}</p>
                  <div className="rating-item">
                    <span>Concept Difficulty:</span>
                    <StarRating rating={reviewsData[course.classId].conceptDifficulty} max={10} />
                  </div>
                  <div className="rating-item">
                    <span>Assignment Difficulty:</span>
                    <StarRating rating={reviewsData[course.classId].assignmentDifficulty} max={10} />
                  </div>
                  <div className="rating-item">
                    <span>Grade Difficulty:</span>
                    <StarRating rating={reviewsData[course.classId].gradeDifficulty} max={10} />
                  </div>
                  <div className="rating-item">
                    <span>Overall Difficulty:</span>
                    <StarRating rating={reviewsData[course.classId].difficulty} max={10} />
                  </div>
                  <div className="rating-item">
                    <span>Usefulness:</span>
                    <StarRating rating={reviewsData[course.classId].usefulness} max={10} />
                  </div>
                  <div className="rating-item">
                    <span>Interest:</span>
                    <StarRating rating={reviewsData[course.classId].interest} max={10} />
                  </div>
                </div>
              ) : (
                <p>Loading ratings...</p>
              )}

              <button onClick={() => handleViewClass(course.classId, reviewsData[course.classId])}>
                View Class
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No classes found.</p>
      )}

      <div className="pagination">
        {Array.from({ length: Math.ceil(classes.length / classesPerPage) }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            className={currentPage === i + 1 ? 'active' : ''}
          >
            {i + 1}
          </button>
        ))}
      </div>

      <footer className="footer">
        <p>Missing a Class?{' '}
          <span className="contact-link" onClick={handleContactUs}>
            Contact us
          </span>
        </p>
      </footer>
    </div>
  );
}

export default ViewClasses;
