import React, { useState, useEffect } from 'react';
import { Range } from 'react-range';

function AdvancedSearch({ onSearch, initialDepartment = '', initialCourseNumber = '' }) {
  const [department, setDepartment] = useState(initialDepartment);
  const [courseNumber, setCourseNumber] = useState(initialCourseNumber);
  const [minReviews, setMinReviews] = useState(0);
  const [difficultyRange, setDifficultyRange] = useState([0, 10]);
  const [minUsefulness, setMinUsefulness] = useState(0);
  const [minInterest, setMinInterest] = useState(0);
  const [minAvgHours, setMinAvgHours] = useState(0);
  const [maxAvgHours, setMaxAvgHours] = useState(30);
  const [conceptDifficulty, setConceptDifficulty] = useState([0, 10]);
  const [assignmentDifficulty, setAssignmentDifficulty] = useState([0, 10]);
  const [gradeDifficulty, setGradeDifficulty] = useState([0, 10]);
  const [sortBy, setSortBy] = useState('');
  const [classLevels, setClassLevels] = useState({
    '1000': false,
    '2000': false,
    '3000': false,
    '4000': false,
    '5000+': false,
  });

  useEffect(() => {
    setDepartment(initialDepartment);
    setCourseNumber(initialCourseNumber);
  }, [initialDepartment, initialCourseNumber]);

  const handleSearch = () => {
    const searchParams = {
      department,
      courseNumber,
      minReviews,
      minDifficulty: difficultyRange[0],
      maxDifficulty: difficultyRange[1],
      minUsefulness,
      minInterest,
      minAvgHours,
      maxAvgHours,
      minConceptDifficulty: conceptDifficulty[0],
      maxConceptDifficulty: conceptDifficulty[1],
      minAssignmentDifficulty: assignmentDifficulty[0],
      maxAssignmentDifficulty: assignmentDifficulty[1],
      minGradeDifficulty: gradeDifficulty[0],
      maxGradeDifficulty: gradeDifficulty[1],
      sortBy,
      classLevels
    };
    onSearch(searchParams);
  };

  const handleClassLevelChange = (level) => {
    setClassLevels((prevLevels) => ({
      ...prevLevels,
      [level]: !prevLevels[level]
    }));
  };

  return (
    <div className="advanced-search">
      <h2>Advanced Search</h2>

      <div>
        <label>Department(s):</label>
        <input
          type="text"
          value={department}
          onChange={(e) => setDepartment(e.target.value)}
          placeholder="Enter up to 5 departments (e.g. MATH,CS,PHYS)"
        />
      </div>

      <div>
        <label>Class Number:</label>
        <input
          type="text"
          value={courseNumber}
          onChange={(e) => setCourseNumber(e.target.value)}
        />
      </div>

      <div>
        <label>Minimum Number of Reviews:</label>
        <input
          type="number"
          value={minReviews}
          onChange={(e) => setMinReviews(Number(e.target.value))}
        />
      </div>

      {/* Difficulty Range Slider */}
      <div>
        <label>Difficulty (Min - Max):</label>
        <Range
          step={0.1}
          min={0}
          max={10}
          values={difficultyRange}
          onChange={(values) => setDifficultyRange(values)}
          renderTrack={({ props, children }) => (
            <div {...props} style={{ height: '6px', width: '100%', backgroundColor: '#ccc' }}>
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div {...props} style={{ height: '16px', width: '16px', backgroundColor: '#999' }} />
          )}
        />
        <div>
          <span>Min: {difficultyRange[0]}</span>
          <span style={{ marginLeft: '20px' }}>Max: {difficultyRange[1]}</span>
        </div>
      </div>

      <div>
        <label>Minimum Usefulness:</label>
        <input
          type="range"
          min="0"
          max="10"
          step="0.1"
          value={minUsefulness}
          onChange={(e) => setMinUsefulness(Number(e.target.value))}
        />
        <span>{minUsefulness}</span>
      </div>

      <div>
        <label>Minimum Interest:</label>
        <input
          type="range"
          min="0"
          max="10"
          step="0.1"
          value={minInterest}
          onChange={(e) => setMinInterest(Number(e.target.value))}
        />
        <span>{minInterest}</span>
      </div>

      <div>
        <label>Average Hours per Week:</label>
        <input
          type="number"
          value={minAvgHours}
          onChange={(e) => setMinAvgHours(Number(e.target.value))}
          placeholder="Min Hours"
        />
        <input
          type="number"
          value={maxAvgHours || ''}
          onChange={(e) => setMaxAvgHours(Number(e.target.value))}
          placeholder="Max Hours"
        />
      </div>

      {/* Concept Difficulty Range */}
      <div>
        <label>Concept Difficulty (Min - Max):</label>
        <Range
          step={0.1}
          min={0}
          max={10}
          values={conceptDifficulty}
          onChange={(values) => setConceptDifficulty(values)}
          renderTrack={({ props, children }) => (
            <div {...props} style={{ height: '6px', width: '100%', backgroundColor: '#ccc' }}>
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div {...props} style={{ height: '16px', width: '16px', backgroundColor: '#999' }} />
          )}
        />
        <div>
          <span>Min: {conceptDifficulty[0]}</span>
          <span style={{ marginLeft: '20px' }}>Max: {conceptDifficulty[1]}</span>
        </div>
      </div>

      {/* Assignment Difficulty Range */}
      <div>
        <label>Assignment Difficulty (Min - Max):</label>
        <Range
          step={0.1}
          min={0}
          max={10}
          values={assignmentDifficulty}
          onChange={(values) => setAssignmentDifficulty(values)}
          renderTrack={({ props, children }) => (
            <div {...props} style={{ height: '6px', width: '100%', backgroundColor: '#ccc' }}>
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div {...props} style={{ height: '16px', width: '16px', backgroundColor: '#999' }} />
          )}
        />
        <div>
          <span>Min: {assignmentDifficulty[0]}</span>
          <span style={{ marginLeft: '20px' }}>Max: {assignmentDifficulty[1]}</span>
        </div>
      </div>

      {/* Grade Difficulty Range */}
      <div>
        <label>Grade Difficulty (Min - Max):</label>
        <Range
          step={0.1}
          min={0}
          max={10}
          values={gradeDifficulty}
          onChange={(values) => setGradeDifficulty(values)}
          renderTrack={({ props, children }) => (
            <div {...props} style={{ height: '6px', width: '100%', backgroundColor: '#ccc' }}>
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div {...props} style={{ height: '16px', width: '16px', backgroundColor: '#999' }} />
          )}
        />
        <div>
          <span>Min: {gradeDifficulty[0]}</span>
          <span style={{ marginLeft: '20px' }}>Max: {gradeDifficulty[1]}</span>
        </div>
      </div>

      <div>
        <label>Sort By:</label>
        <select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
          <option value="">None</option>
          <option value="highestReviews">Highest Number of Reviews</option>
          <option value="lowestReviews">Lowest Number of Reviews</option>
          <option value="highestRating">Highest Overall Rating</option>
          <option value="lowestRating">Lowest Overall Rating</option>
          <option value="highestDifficulty">Highest Difficulty</option>
          <option value="lowestDifficulty">Lowest Difficulty</option>
          <option value="highestUsefulness">Highest Usefulness</option>
          <option value="lowestUsefulness">Lowest Usefulness</option>
          <option value="highestInterest">Highest Interest</option>
          <option value="lowestInterest">Lowest Interest</option>
        </select>
      </div>

      <div>
        <label>Class Levels:</label>
        <div>
          <input
            type="checkbox"
            checked={classLevels['1000']}
            onChange={() => handleClassLevelChange('1000')}
          />
          <label>1000 level</label>
          <input
            type="checkbox"
            checked={classLevels['2000']}
            onChange={() => handleClassLevelChange('2000')}
          />
          <label>2000 level</label>
          <input
            type="checkbox"
            checked={classLevels['3000']}
            onChange={() => handleClassLevelChange('3000')}
          />
          <label>3000 level</label>
          <input
            type="checkbox"
            checked={classLevels['4000']}
            onChange={() => handleClassLevelChange('4000')}
          />
          <label>4000 level</label>
          <input
            type="checkbox"
            checked={classLevels['5000+']}
            onChange={() => handleClassLevelChange('5000+')}
          />
          <label>5000+ level</label>
        </div>
      </div>

      <button onClick={handleSearch}>Search</button>
    </div>
  );
}

export default AdvancedSearch;
