// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../style/header.css'; // Make sure the path to your CSS file is correct

function Header({ loggedIn, onProfileClick, onLoginClick }) {
  return (
    <header className="header">
      <h1>
        <Link to="/" className="header-title">
          UGA Course Genie
        </Link>
      </h1>
      {loggedIn ? (
        <button onClick={onProfileClick}>View Profile</button>
      ) : (
        <button onClick={onLoginClick}>Log In</button>
      )}
    </header>
  );
}

export default Header;

