import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/Register.css'; // Import a CSS file for styling

function Register() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [major, setMajor] = useState('');
  const [grade, setGrade] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const [showVerificationPopup, setShowVerificationPopup] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [emailError, setEmailError] = useState(''); // State to manage email error
  const [filteredMajors, setFilteredMajors] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const majors = [
    "Biology/biological sciences",
    "Psychology",
    "Finance",
    "Marketing/marketing management",
    "Management information systems",
    "Computer science",
    "Insurance",
    "Business administration and management",
    "International relations and affairs",
    "Political science and government",
    "Accounting",
    "Business/managerial economics",
    "Mechanical engineering",
    "Human development and family studies",
    "Advertising",
    "Journalism",
    "Public relations/image management",
    "Sport and fitness administration/management",
    "Kinesiology and exercise science",
    "Real estate",
    "Early childhood education and teaching",
    "Speech communication and rhetoric",
    "Public health education and promotion",
    "International business/trade/commerce",
    "Nutrition sciences",
    "English language and literature",
    "Economics",
    "Art/art studies",
    "Digital communication and media/multimedia",
    "Fashion merchandising",
    "Communication sciences and disorders",
    "Sociology",
    "Family resource management studies",
    "Biological and physical sciences",
    "Criminal justice/law enforcement administration",
    "Animal sciences",
    "Genetics",
    "Mathematics",
    "Consumer economics",
    "Biological/biosystems engineering",
    "Biochemistry and molecular biology",
    "Civil engineering",
    "Cognitive science",
    "Business/commerce",
    "History",
    "Philosophy",
    "Agribusiness/agricultural business operations",
    "Social work",
    "Landscape architecture",
    "Ecology",
    "Wildlife, fish and wildlands science and management",
    "Special education and teaching",
    "Statistics",
    "Electrical and electronics engineering",
    "Housing and human environments",
    "Chemistry",
    "Anthropology",
    "Linguistics",
    "Microbiology",
    "Environmental health",
    "Spanish language and literature",
    "Agricultural economics",
    "Biochemical engineering",
    "Cell/cellular biology and histology",
    "Computer engineering",
    "Pharmaceutical sciences",
    "Geography",
    "Environmental/environmental health engineering",
    "Fine/studio arts",
    "Atmospheric sciences and meteorology",
    "Hospitality administration/management",
    "Health and physical education/fitness",
    "Drama and dramatics/theatre arts",
    "Biotechnology",
    "Horticultural science",
    "Women's studies",
    "Mathematics teacher education",
    "Music",
    "Music performance",
    "Soil science and agronomy",
    "Geology/earth science",
    "Film/cinema/video studies",
    "Natural resource recreation and tourism",
    "Social studies teacher education",
    "German language and literature",
    "Poultry science",
    "Junior high/intermediate/middle school education and teaching",
    "Agriculture",
    "English/language arts teacher education",
    "Forestry",
    "Agricultural teacher education",
    "Music teacher education",
    "Comparative literature",
    "Classics and classical languages, literatures, and linguistics",
    "Agricultural communication/journalism",
    "Physics",
    "Religion/religious studies",
    "Food science",
    "Science teacher education/general science teacher education",
    "Agricultural engineering"
  ];

  const handleMajorChange = (e) => {
    const value = e.target.value;
    setMajor(value);

    if (value) {
      const filtered = majors.filter((majorOption) =>
        majorOption.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredMajors(filtered);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleMajorSelect = (major) => {
    setMajor(major);
    setShowDropdown(false);
  };

  const handleRegisterSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch('/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, username, password, major, grade }),
    });

    if (response.status === 409) {
      // If the email already exists, display an error message
      setEmailError('Email already exists');
    } else if (response.ok) {
      setShowVerificationPopup(true); // Show the verification pop-up
    } else {
      console.error('Registration failed');
    }
  };

  const handleVerifySubmit = async (event) => {
    event.preventDefault();

    const response = await fetch('/api/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, verification_code: verificationCode }), // Send email along with the verification code
    });

    if (response.ok) {
      // Automatically log in the user with their registered credentials
      const loginResponse = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }), // Use the email and password entered during registration
      });

      if (loginResponse.ok) {
        sessionStorage.setItem('loggedIn', 'true'); // Set session storage to indicate the user is logged in
        navigate('/'); // Redirect to the homepage after successful login
      } else {
        console.error('Login failed after verification');
      }
    } else {
      console.error('Verification failed');
    }
  };

  return (
    <div className="register-container">
      <h1>Register</h1>
      <form onSubmit={handleRegisterSubmit}>
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
        <label>Username:</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <label>Password:</label>
        <input
          type={showPassword ? 'text' : 'password'} // Toggle input type based on showPassword state
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <div>
          <input
            type="checkbox"
            checked={showPassword}
            onChange={() => setShowPassword(!showPassword)}
          />
          <label>Show Password</label>
          </div>
        <label>Major:</label>
        <input
          type="text"
          value={major}
          onChange={handleMajorChange}
          onFocus={() => setShowDropdown(true)}
          onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
          required
        />
        {showDropdown && (
          <ul className="dropdown">
            {filteredMajors.map((majorOption, index) => (
              <li key={index} onClick={() => handleMajorSelect(majorOption)}>
                {majorOption}
              </li>
            ))}
          </ul>
        )}
        <label>Grade:</label>
<select
  value={grade}
  onChange={(e) => setGrade(e.target.value)}
  required
>
  <option value="">Select Grade</option>
  <option value="Freshman">Freshman</option>
  <option value="Sophomore">Sophomore</option>
  <option value="Junior">Junior</option>
  <option value="Senior">Senior</option>
  <option value="Alumni">Alumni</option>
</select>
        <button type="submit">Register</button>
      </form>

      {showVerificationPopup && (
        <div className="overlay" onClick={() => setShowVerificationPopup(false)}>
          <div className="verification-popup" onClick={(e) => e.stopPropagation()}>
            <h2>Enter Verification Code</h2>
            <form onSubmit={handleVerifySubmit}>
              <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
                maxLength={4}
                placeholder="Enter 4-digit code"
              />
              <button type="submit">Verify</button>
            </form>
            <button onClick={() => setShowVerificationPopup(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
