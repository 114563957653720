// src/components/ReviewItem.js

import React from 'react';
import '../style/ReviewItem.css';

function ReviewItem({ review }) {
  // Get color based on rating (1-10)
  const getRatingColor = (rating) => {
    if (rating >= 8) return 'green';
    if (rating >= 5) return 'orange';
    return 'red';
  };

  // Render a colored bar for rating out of 10
  const renderBar = (rating) => (
    <div className="rating-bar-container">
      <div className="rating-bar" style={{ width: `${rating * 10}%`, backgroundColor: getRatingColor(rating) }}></div>
    </div>
  );

  // Function to render yes/no (boolean) values with colored badges
  const renderBooleanBadge = (value, label) => (
    <span className={`boolean-badge ${value ? 'yes' : 'no'}`}>
      {value ? `${label}: Yes` : `${label}: No`}
    </span>
  );

  // Function to render tags like 'testHeavy', 'lotsOfHomework', etc.
  const renderTagBadge = (tagValue, label) => (
    <span className={`tag-badge ${tagValue ? 'active' : 'inactive'}`}>
      {label}
    </span>
  );

  return (
    <div className="review-item-container">
      <h3>Professor: {review.professorName}</h3>
      <p><strong>Year:</strong> {review.year}</p>

      <div className="rating-section">
        <p><strong>Concept Difficulty:</strong></p>
        {renderBar(review.conceptDifficulty)}
      </div>

      <div className="rating-section">
        <p><strong>Assignment Difficulty:</strong></p>
        {renderBar(review.assignmentDifficulty)}
      </div>

      <div className="rating-section">
        <p><strong>Grade Difficulty:</strong></p>
        {renderBar(review.gradeDifficulty)}
      </div>

      <div className="rating-section">
        <p><strong>Overall Difficulty:</strong></p>
        {renderBar(review.difficulty)}
      </div>

      <div className="rating-section">
        <p><strong>Usefulness:</strong></p>
        {renderBar(review.usefulness)}
      </div>

      <div className="rating-section">
        <p><strong>Interest:</strong></p>
        {renderBar(review.interest)}
      </div>

      {/* Render boolean values */}
      <div className="boolean-section">
        {renderBooleanBadge(review.onlineClass, 'Online Class')}
        {renderBooleanBadge(review.wouldTakeAgain, 'Would Take Again')}
      </div>

      {/* Render tags */}
      <div className="tags-section">
        <p><strong>Tags:</strong></p>
        {renderTagBadge(review.testHeavy, 'Test Heavy')}
        {renderTagBadge(review.lotsOfHomework, 'Lots of Homework')}
        {renderTagBadge(review.projectHeavy, 'Project Heavy')}
        {renderTagBadge(review.textbookNeeded, 'Textbook Needed')}
        {renderTagBadge(review.easyButBoring, 'Easy but Boring')}
        {renderTagBadge(review.hardButInteresting, 'Hard but Interesting')}
        {renderTagBadge(review.hardButLearnedALot, 'Hard but Learned a Lot')}
        {renderTagBadge(review.nightTests, 'Night Tests')}
        {renderTagBadge(review.onlineTests, 'Online Tests')}
      </div>

      {/* Comments, Advice, and Hardest Part of Class */}
      <p><strong>Comments:</strong> {review.comments || 'No comments provided'}</p>
      <p><strong>Advice:</strong> {review.advice || 'No advice provided'}</p>
      <p><strong>Hardest Part of Class:</strong> {review.hardestPartOfClass || 'Not specified'}</p>
    </div>
  );
}

export default ReviewItem;
